import { useEffect, useState } from "react";
// import { DatePicker } from "../DatePicker";
import { Popover, message } from "antd";

import { Input } from "antd";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/api-urls";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import {
  addItemToStorage,
  addSongToPlaylist,
  getPlaylistItemsSpotify,
  getRefreshedToken,
} from "../../utils";
import DatePicker from "../DatePicker/DatePickerWithoutFormik";
import PlanToast from "../PlanToast/PlanToast";
import Spinner from "../Spinner/Spinner";
import { AddToPlayListModalStyle } from "./AddToPlayListModal.style";
import { useParams } from "react-router";

interface AddToPlayListModalProps {
  isModalVisible?: boolean;
  hideUriInput?: boolean;
  preRelease?: boolean;
  hideRadioButtons?: boolean;
  setIsModalVisible?: (_: boolean) => void;
  songURI?: string;
  setSongURI?: (_: string) => void;
  existSongUri?: string;
  dataSearched?: any;
}

const AddToPlayListModalSearch = (props: AddToPlayListModalProps) => {
  const {
    isModalVisible,
    setIsModalVisible,
    hideRadioButtons,
    hideUriInput,
    preRelease,
    dataSearched,
  } = props;
  const { id } = useParams();
  const [setLoading, showLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [limitText, setLimitText] = useState("");
  const [enableDatePicker, setEnableDatePicker] = useState(true);
  const dispatch = useDispatch();
  const [searchPlaylistText, setSearchPlaylistText] = useState('')
  const { state } = usePlaylistContext();
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  const handleOk = () => {
    setIsModalVisible(false);
    setSearchPlaylistText('')
    setSelectPlayListOpen(false);

  };
  const [arrayPositions, setArrayPositions] = useState([])
  const [positionText, setPositionText] = useState(0)
  const [setButtom, showButtom] = useState("");
  const [setDate, showDate] = useState(null);
  const [release, setRelease] = useState("Immediately");
  const [selectPlayListOpen, setSelectPlayListOpen] = useState(false);
  const [selectedPlayList, setSelectedPlayList] = useState([]);
  const [selectedName, setSelectedName] = useState([])
  // const [showAccount, setAccount] = useState([]);
  const [totalPlaylist, setTotalPlaylist] = useState([]);
  const [totalPlaylistNumber, setTotalPlaylistNumber] = useState(0);
  const [note, setNote] = useState("");

  const [data, setData] = useState({
    playlistId: [],
    startTime: "",
    endTime: "",
    position: 1,
    status: "past",
    searchedSongData: "",
  });

  const onHandle = (date, dateString) => {
    showDate(date);
    // showButtom("");
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // setSelectedPlayList([id])
    fetch(`${BASE_URL}/get/packages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: `${user?.email ? user?.email : user?.email}`,
        userId: user?.userId,
        custId: user?.custId
      }),
    })
      .then((res) => res.json())
      .then((data) => { })
      .catch((error) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSwitch = (value) => {
    setEnableDatePicker(!enableDatePicker);
    if (value === true) {
      showButtom("disabled");
    } else {
      showButtom("");
    }
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    // setSelectedPlayList([id])
    setData({
      playlistId: [],
      startTime: "",
      endTime: "",
      position: 1,
      status: "past",
      searchedSongData: "",
    });
    setNote("");
    // setSelectedPlayList([]);
    setIsModalVisible(false);
    setSearchPlaylistText('')
    setSelectPlayListOpen(false);

  };

  const addToPlaylistLoop = () => {
    showLoading(true);
    if (data.playlistId.length > 1) {
      if (data.position > 0) {
        data.playlistId.map((a, i) => {
          getPlaylistItemsSpotify(a, loginState.token).then((res) => {
            if (res.total === 0) {
              handleAddSong(
                a,
                {
                  ...data,
                  position: 0,
                  playlistId: a,
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            } else if (data.position > res.total) {
              handleAddSong(
                a,
                {
                  ...data,
                  position: res.total + 1,
                  playlistId: a,
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
              // 1               1
            } else if (data.position === res.total) {
              handleAddSong(
                a,
                {
                  ...data,
                  playlistId: a,
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            } else if (data.position < res.total) {
              handleAddSong(
                a,
                {
                  ...data,
                  playlistId: a,
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            }
          });
        });
      } else {
        showLoading(false);
        toast.error("Position is required");
      }
    } else {
      if (arrayPositions.length && arrayPositions[0] > 0) {
        arrayPositions.map((a, i) => {
          getPlaylistItemsSpotify(data.playlistId[0], loginState.token).then((res) => {
            //console.log(arrayPositions, res.total)
            //console.log(a)
            if (res.total === 0) {
              handleAddSong(
                data.playlistId[0],
                {
                  ...data,
                  position: i + 1,
                  playlistId: data.playlistId[0],
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            } else if (Number(a) > res.total) {
              handleAddSong(
                data.playlistId[0],
                {
                  ...data,
                  position: res.total + 1,
                  playlistId: data.playlistId[0],
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
              // 1               1
            } else if (Number(a) === res.total) {
              handleAddSong(
                data.playlistId[0],
                {
                  ...data,
                  position: a,
                  playlistId: data.playlistId[0],
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            } else if (Number(a) < res.total) {
              handleAddSong(
                data.playlistId[0],
                {
                  ...data,
                  position: a,
                  playlistId: data.playlistId[0],
                  status: release === "Later" ? "future" : "past",
                },
                dataSearched,
                data.playlistId.length - 1 === i ? true : false
              );
            }
          });
        });
      } else {
        showLoading(false);
        toast.error("Position is required");
      }
    }
  };

  const handleAddSong = (playlistId, values, selectedSong, lastValue) => {
    dispatch({
      type: "SET_SEARCHED_SONG_DATA",
      payload: selectedSong.songUri,
    });

    addSongToPlaylist(
      Array.isArray(playlistId) ? playlistId : [playlistId],
      selectedSong.songUri,
      loginState.token,
      loginState.refreshToken,
      loginState.userId,
      values.position,
      setDate,
      values.endTime,
      values.status,
      note
    )
      .then(async (response) => {
        message.info(
          values.startTime
            ? "Song scheduled successfully"
            : "Song added to playlist"
        );
        if (lastValue) {
          showLoading(false);
          if (id) {
            setSelectedPlayList([id])
          } else {
            setSelectedPlayList([])
          }
          setArrayPositions([])
          setPositionText(0)
          setData({
            playlistId: [],
            startTime: "",
            endTime: "",  
            position: 1,
            status: "past",
            searchedSongData: "",
          });
          setIsModalVisible(false);
          // window.location.reload()
        }
      })
      .catch((e) => {
        message.error("Error while adding song");
        if (lastValue) {
          showLoading(false);
        }
      });
  };

  /**
   * This function is filtering the playlists for dropdown based on owner
   * because we want the dropdown to show only those playlists scheduled by
   * songplace
   *
   * @returns {playlist[]} filtered playlists
   */
  const getPlaylistItems = (playlists) => {
    const playlist = [];
    playlists.map((data) => {
      const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
      if (data?.owner?.display_name === spotifyUser?.username) {
        playlist.push({
          key: data.id,
          value: data.name,
        });
      }
    });

    return playlist;
  };

  const getPlaylists = (offset) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=50&offset=${offset}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then((genreResponse) => {
        setTotalPlaylist([...totalPlaylist, ...genreResponse?.data.items]);
        if (totalPlaylistNumber < genreResponse?.data.total) {
          setTotalPlaylistNumber(genreResponse?.data.total);
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  };
  // const [offset,setOffset] = useState(0)
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // @ts-ignore
    const url = new URL(window.location);
    const id = url.searchParams.get("id");
    if (
      isModalVisible &&
      (totalPlaylist.length < totalPlaylistNumber || totalPlaylist.length === 0)
    ) {
      getPlaylists(totalPlaylist.length);
    } else if (isModalVisible) {
      let nameplaylist = totalPlaylist.filter(obj => obj.id === id).length ? totalPlaylist.filter(obj => obj.id === id)[0]?.name : ''
      // //console.log(totalPlaylist, arr, 'adsasdasdasd')
      if (nameplaylist) {
        setSelectedPlayList([nameplaylist])
        let obj = { ...data }
        //console.log('dadsadasdsadsa')
        obj.playlistId = [id]
        setData(obj)
      } else {
        setSelectedPlayList([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, totalPlaylistNumber, totalPlaylist]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (preRelease) {
      setRelease("Later");
      handleSwitch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preRelease]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setData({
      ...data,
      searchedSongData: props.songURI,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.songURI]);


  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
    >
      <div
        onClick={() => {
          setSelectPlayListOpen(false);
        }}
      >
        <div className="addToPlaylistHead">
          <h1 className="heading">Add to playlist</h1>
          <p className="description" onClick={() => {
            //console.log(selectedPlayList, 'selectedPlayList')
          }}>
            To schedule a song in your playlist, please select the playlist
            below.
          </p>
        </div>
        <div className="formAddToPlaylist">
          <div className="formPlaylist">
            <div className="playlistPosition">
              <label className="label">
                <p>Playlists</p>
                <p className="limitText">{limitText}</p>
                <div
                  className="playlistInput"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectPlayListOpen(true);
                  }}
                // onBlur={() => {
                //   setSelectPlayListOpen(false);
                // }}
                >
                  <div className="playlistInputText">
                    {selectedPlayList.map((a, i) => (
                      <span>{a}</span>
                    ))}
                    <input className="playlistInputTextSearch" value={searchPlaylistText} placeholder="Search Playlist" onChange={(ev) => {
                      setSearchPlaylistText(ev.target.value)
                    }} />
                  </div>
                  <div
                    className={`optionsList ${selectPlayListOpen ? "active" : ""
                      }`}
                  >
                    {getPlaylistItems(totalPlaylist).map(
                      (option: {
                        key: string;
                        value: string;
                        disabled?: boolean;
                      }) => {
                        return option?.value?.toLowerCase().includes(searchPlaylistText?.toLowerCase()) ? (
                          <div
                            className="optionName"
                            id="add-songs-mode-options"
                            onClick={(value) => {
                              value.stopPropagation();
                              if (data.playlistId.includes(option.key)) {
                                setLimitText("");
                                let obj = { ...data };
                                obj.playlistId.splice(
                                  obj.playlistId.indexOf(option.key),
                                  1
                                );
                                // @ts-ignore
                                setData(obj);
                                let playlist = [...selectedPlayList];
                                playlist.splice(
                                  playlist.indexOf(option.value),
                                  1
                                );
                                setSelectedPlayList([...playlist]);
                              } else {
                                if (
                                  user?.free &&
                                  data?.playlistId.length >= 1
                                ) {
                                  setLimitText(
                                    "Upgrade your plan to select multiple playlists"
                                  );
                                } else {
                                  setLimitText("");
                                  let obj = { ...data };
                                  // @ts-ignore
                                  obj.playlistId = [
                                    ...obj.playlistId,
                                    option.key,
                                  ];
                                  setData(obj);
                                  let playlist = [...selectedPlayList];
                                  playlist = [...playlist, option.value];
                                  setSelectedPlayList([...playlist]);
                                }
                              }
                            }}
                          >
                            <div className="optionText">
                              <p>{option.value}</p>
                              <div
                                className={`optionMark ${data.playlistId.includes(option.key)
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                <svg
                                  width="13"
                                  height="10"
                                  viewBox="0 0 13 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 5.5L5 9L12 1.5"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      }
                    )}
                  </div>
                </div>
              </label>
              <label className="label">
                <p>Position of the song in playlist</p>

                {selectedPlayList.length > 1 ?
                  <Input
                    type="number"
                    style={{ border: 'none', padding: '7.5px 10px' }}
                    min={1}
                    bordered={false}
                    value={data.position}
                    placeholder="Position of the song in playlist"
                    onChange={(value) => {
                      let obj = { ...data };
                      obj.position = Number(value.target.value);
                      setData(obj);
                    }}
                  />
                  :
                  <>
                  <Popover content={<p>Use space or enter to add multiple positions</p>} placement="bottomLeft">
                    <div
                      className="playlistInput">
                      <div className="playlistInputText">
                        {arrayPositions.map((a, i) => (
                          <span>{a}</span>
                        ))}
                        <Input
                          type="number"
                          min={1}
                          bordered={false}
                          value={positionText === 0 ? 'Please enter position' : positionText}
                          placeholder="Position of the song in playlist"
                          onChange={(value) => {
                            setPositionText(Number(value.target.value))
                          }}
                          onBlur={(ev) => {
                            if (ev.currentTarget.value) {

                              let arr = [...arrayPositions, ev.currentTarget.value]
                              //console.log(arr)
                              setArrayPositions(arr)
                              setPositionText(0)
                            }
                          }}
                          onKeyDown={(ev) => {
                            if (ev.keyCode === 13 || ev.keyCode === 32) {
                              if (ev.currentTarget.value) {
                                let arr = [...arrayPositions, ev.currentTarget.value]
                                //console.log(arr)
                                setArrayPositions(arr)
                                setPositionText(0)
                              }
                            } else if (ev.keyCode === 8) {
                              if (!ev.currentTarget.value) {

                                let arr = [...arrayPositions]
                                arr.pop()
                                setArrayPositions(arr)
                                setPositionText(0)
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    </Popover>
                  </>



                }

              </label>
            </div>
            {hideUriInput ? null : (
              <label className="label">
                <p>Song URI</p>
                <Input
                  // type="text"
                  placeholder="Song URI"
                  value={data.searchedSongData}
                  onChange={(value: any) => {
                    let obj = { ...data };
                    obj.searchedSongData = value.target.value;
                    setData(obj);
                  }}
                />
              </label>
            )}
            {hideRadioButtons ? null : (
              <div className="release">
                <PlanToast
                  available={["pro", "unlimited"]}
                  titleHeading={"Schedule Start date"}
                >
                  <label
                    onClick={() => {
                      setRelease("Immediately");
                      handleSwitch(false);
                      let obj = { ...data };
                      obj.startTime = "";
                      obj.endTime = ""
                      setData(obj);
                    }}
                  >
                    <div className="radioCheck">
                      {release === "Immediately" ? <div></div> : null}
                    </div>
                    Immediately
                  </label>
                  <label
                    onClick={() => {
                      setRelease("Later");
                      handleSwitch(true);
                      let obj = { ...data };
                      obj.startTime = "";
                      obj.endTime = ""
                      setData(obj);
                    }}
                  >
                    <div className="radioCheck">
                      {release === "Later" ? <div></div> : null}
                    </div>
                    Later
                  </label>
                </PlanToast>
              </div>
            )}
            <div
              className="playlistPosition"
              style={{ marginTop: preRelease ? "20px" : "auto" }}
            >
              {setButtom ? (
                <label className="label">
                  <p>Start date</p>
                  <DatePicker
                    showNow={false}
                    defaultValue={moment()}
                    type="datetime-local"
                    value={data.startTime ? moment(data.startTime) : moment()}
                    format={"YYYY-MM-DD hh:mm"}
                    onChange={(value, valueDate) => {
                      onHandle(value, valueDate);
                      let obj = { ...data };
                      obj.startTime = value ? value.toISOString() : undefined;
                      setData(obj);
                    }}
                   // Disable past dates
                   disabledDate={(current) => {
                    return current && current < moment().startOf('day');
                  }}
                  // Disable past times if the selected date is today
                  disabledTime={(current) => {
                    const now = moment();
                    if (current && current.isSame(now, 'day')) {
                      return {
                        disabledHours: () => Array.from({ length: now.hours() }, (_, i) => i),
                        disabledMinutes: () => {
                          // Only disable minutes for the current hour
                          if (current.hours() === now.hours()) {
                            return Array.from({ length: now.minutes() }, (_, i) => i);
                          }
                          return [];
                        },
                      };
                    }
                    return {};
                  }}
                  />
                </label>
              ) : null}
              <label className="label" style={{ position: "relative" }}>
                <PlanToast
                  available={["pro", "unlimited"]}
                  titleHeading={"End date"}
                >
                  <p>End date</p>
                  <DatePicker
                    showNow={false}
                    type="datetime-local"
                    format={"YYYY-MM-DD hh:mm"}
                    value={data.endTime ? moment(data.endTime) : null}
                    onChange={(value) => {
                      let obj = { ...data };
                      obj.endTime = value ? value.toISOString() : undefined;
                      setData(obj);
                    }}
                    disabledDate={(current) => {
                      if (setDate) {
                        let customDate =
                          moment(setDate).format("YYYY-MM-DD hh:mm");
                        return (
                          current &&
                          moment(customDate, "YYYY-MM-DD hh:mm") >
                          moment(current, "YYYY-MM-DD hh:mm")
                        );
                      } else {
                        let customDate = moment(new Date()).format(
                          "YYYY-MM-DD hh:mm"
                        );
                        return (
                          current &&
                          moment(customDate, "YYYY-MM-DD hh:mm") >
                          moment(current, "YYYY-MM-DD hh:mm")
                        );
                      }
                    }}
                  />
                </PlanToast>
              </label>
            </div>
          </div>

          <label className="label addNoteTextLabel">
            <PlanToast
              available={["pro", "unlimited"]}
              titleHeading={"Add note"}
            >
              <p style={{ marginTop: preRelease ? "20px" : "auto" }}>
                Add note
              </p>
              <Input.TextArea
                className="addNoteText"
                placeholder="Write your notes here"
                rows={4}
                onChange={(ev) => {
                  setNote(ev.target.value);
                }}
              />
            </PlanToast>
          </label>
        </div>
        <div className="buttonDiv">
          <button
            className="addSong"
            disabled={(setLoading || !selectedPlayList?.length) ? true : false}
            onClick={() => addToPlaylistLoop()}
          >
            {setLoading ? <Spinner /> : "Add"}
          </button>
        </div>
      </div>
    </AddToPlayListModalStyle>
  );
};

export default AddToPlayListModalSearch;
