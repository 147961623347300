import { Drawer } from "antd";
import "antd/dist/antd.min.css";
import axios from "axios";
import querystring from "querystring";
import React, { createContext, useContext, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "./assets";
import { AuthenticatedRoute, PageHeader, SideBar as SidebarComp, TopNavbar } from "./components";
import { InnerPageLayoutStyle1 } from "./components/InnerPageLayout/InnerPageLayout.style";
import {
  SPOTIFY_CODE,
  SPOTIFY_REFRESH_TOKEN,
  SPOTIFY_TOKEN,
  SPOTIFY_USER_INFO
} from "./constants/storage";
import { LoginContextProvider, useLoginContext } from "./context";
import { PlaylistContextProvider } from "./context/PlaylistContext";
import './index.css';
import {
  Callback,
  CancelSub,
  FuturePlaylist,
  Home,
  landingPage as LandingPage,
  Playlist,
  Search,
  Setting,
  ShiftPage,
  Signup,
  SignupCallback
} from "./pages";
import ScheduleSong from "./pages/Future_Playlist/ScheduleSong";
import { LoginStyle } from "./pages/Login/Login.style";
import BaseStyle from "./styles/Base.style";
import { GlobalThemeProvider } from "./theme/themeProvider";
import {
  Auth0Logout,
  addItemToStorage,
  addUserProfileAction,
  deleteItemFromStorage,
  getItemFromStorage,
  getRefreshedToken,
  isEmptyOrNil
} from "./utils";
import { ScheduleContextProvider } from "./context/ScheduleContext";
import UserGuid from "./pages/User_Guide/UserGuid";
import ShareDetailPage from "./pages/landingPage/ShareDetailPage";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface SidebarContextType {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

const SidebarProvider: React.FC = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
type sideBarProp = {
  classname?: string;
};
const SideBar = (props: sideBarProp) => {
  const { showSidebar } = useContext(SidebarContext) as SidebarContextType;

  return showSidebar ? <SidebarComp className={props.classname} /> : null;
};
const Layout: React.FC = ({ children }) => {

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <InnerPageLayoutStyle1>
      <SideBar classname={'sidebarForPc'} />
      <div className="page-content" id="page-content">
        <TopNavbar showDrawer={showDrawer}
          onClose={onClose} />
        {children}
      </div>

      {/* {showCurrent === "no-playlist" ? (

 ) : showCurrent === "future" ? (
   <div className="page-content" id="page-content2">
     <TopNavbar showDrawer={showDrawer}
       onClose={onClose}/>
     {children}
   </div>
 ) : (
   <div className="page-content" id="page-content1">
     <TopNavbar showDrawer={showDrawer}
       onClose={onClose}/>
     {children}
   </div>
 )} */}

      <Drawer className="mainSideBar" title={false} placement="left" onClose={onClose} open={open}>
        <SideBar classname={"sidebarForMob"} />
      </Drawer>

      {/* {isMobile() && <BottomNavigation />} */}
      {/* </div> */}
    </InnerPageLayoutStyle1>
  );
}

const RoutesList = () => {
  const { state, dispatch } = useLoginContext();
  const expired = false
  useEffect(() => {
    let rawCode = getItemFromStorage(SPOTIFY_CODE);
    const code = rawCode?.split("#")[0];
    if (isEmptyOrNil(code)) return;
    if (code) {
      // console.log('codecodecodecode')
      axios(
        "https://accounts.spotify.com/api/token?" +
        "scope=playlist-modify-private playlist-modify-public playlist-read-private ",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic " +
              new window.Buffer(
                process.env.REACT_APP_CLIENT_ID +
                ":" +
                process.env.REACT_APP_CLIENT_SECRET
              ).toString("base64"),
          },
          data: querystring.stringify({
            grant_type: "authorization_code",
            code: code,
            // redirect_uri: "http://3.9.144.131:3000/callback",
            redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          }),
          method: "POST",
        }
      )
        .then(async (tokenResponse) => {
          axios("https://api.spotify.com/v1/me", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenResponse.data.access_token}`,
            },
          })
            .then((response) => {
              console.log('responseeeresponseeeresponseee', response)
              const getItem = JSON.parse(
                localStorage.getItem("userInfo")
              )?.userId;

              const data = {
                username: response?.data?.display_name,
                image:
                  response?.data?.image !== undefined
                    ? response?.data?.image[0]?.url
                      ? response?.data?.image[0]?.url
                      : "dummy"
                    : "dummy",
                code: code,
                token: response?.data?.access_token,
                refresh: tokenResponse.data.refresh_token,
                spotify_user_id: response?.data?.id
              };
              localStorage.setItem(
                "spotifyUser",
                JSON.stringify(data, null, " ")
              );
              addUserProfileAction(
                response?.data?.display_name,
                response?.data?.id,
                response?.data?.image !== undefined
                  ? response?.data?.image[0]?.url
                    ? response?.data?.image[0]?.url
                    : "dummy"
                  : "dummy",
                code,
                tokenResponse.data.access_token,
                tokenResponse.data.refresh_token,
                getItem
              );

              addItemToStorage(SPOTIFY_TOKEN, tokenResponse.data.access_token);

              addItemToStorage(
                SPOTIFY_REFRESH_TOKEN,
                tokenResponse.data.refresh_token
              );

              dispatch({
                type: "ADD_APP_TOKEN",
                payload: {
                  token: tokenResponse.data.access_token,
                  refreshToken: tokenResponse.data.refresh_token,
                },
              });
            })
            .catch((error) => {

            });
        })
        .catch(async (error) => {
          // console.log(error, 'errorerror')
          // setExpired(true)
          if (
            error.response?.data?.error?.status === 401 ||
            error.response?.status === 400
          ) {
            getRefreshedToken(state.refreshToken).then((response) => {
              localStorage.setItem(SPOTIFY_TOKEN, response?.data?.access_token);
              dispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            });
          }
        });
    }
    // }, [token, state, dispatch]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");
    Auth0Logout();

    dispatch({
      type: "SET_LOGIN",
      payload: false,
    });
  };


  return (
    <>
      {!expired ?
        <BrowserRouter>
          <SidebarProvider>
            <Routes>
              <Route path="/shift" element={<ShiftPage />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/cancel" element={<CancelSub />} />
              <Route
                path="/"
                element={
                  <AuthenticatedRoute redirectTo="/">

                    <LandingPage />

                    {/* <Login /> */}
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/track-detail"
                element={
                  <ShareDetailPage />
                }
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="/created" element={<SignupCallback />} />

              <Route
                path="/dashboard"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Search />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/future_playlist"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <FuturePlaylist />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/schedule_song"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <ScheduleSong />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <Search />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/playlist"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <Playlist />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/playlist/:playlistId"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <Playlist />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <Setting />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/home"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <Home />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/user-guide"
                element={
                  <AuthenticatedRoute redirectTo="/">
                    <Layout>
                      <UserGuid />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <AuthenticatedRoute redirectTo="/home">
                    <Layout>
                      <Home />
                    </Layout>
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </SidebarProvider>

        </BrowserRouter>
        :
        <LoginStyle>
          <div className="background-col-1">
            <div className="background">
              <div className="expire-div" style={{}}>
                <Alert />
              </div>
              <h3 style={{}} className="h3">
                The session has been expired.
              </h3>
              <p className="ppp" style={{}}>
                Please relogin to continue using Songplace.
              </p>
              <div className="dives">
                <button onClick={handleLogout} className="btn1">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </LoginStyle>
      }
    </>
  );
};

function App() {
  return (
    <div className="App">
      <PageHeader
        title="Songplace - The best platform to manage your Spotify playlists"
        description="The best platform to manage your Spotify playlists."
        image="./static/mini-logo.png"
      />

      <LoginContextProvider>
        {/* <ScheduleContextProvider> */}
        <PlaylistContextProvider>
          <GlobalThemeProvider>
            <ToastContainer />
            <BaseStyle />
            <RoutesList />
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2C2C2C" }}
              buttonStyle={{
                color: "white",
                fontSize: "13px",
                background: "#0059d1",
              }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
              {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
          </GlobalThemeProvider>
        </PlaylistContextProvider>
        {/* </ScheduleContextProvider> */}
      </LoginContextProvider>
    </div>
  );
}

export default App;
