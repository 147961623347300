import ErrorIcon from "@mui/icons-material/Error";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Dropdown, Popover, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CalenderIcon, VerticalDots } from "../../assets";
import { FuturePlaylistSongTableStyle } from "./FuturePlaylistSongTable.style";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PopoverStyle } from "./Playlist.style";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { KeyCode, isSelected, truncateText } from "../../utils";
import { TablesStyle } from "../Playlist/Playlist.style";
type Props = {
  menu: any;
  scheduleData: any;
  playlistState: any;
  bulkAction?: any;
  selectSongFunction?: any;
  currentPage?: any;
  selectedSongs?: any;
  setBulkAction?: any;
  selectMultpleSongFunction?: any;
  selectedIndex?: any;
  setSelectedIndex?: any;
  selectSingleSong?: any;
};
function ScheduledPlaylistSongTable({ menu, scheduleData, playlistState,
  bulkAction,
  selectSongFunction,
  currentPage,
  selectedSongs,
  setBulkAction,
  selectMultpleSongFunction,
  selectedIndex,
  setSelectedIndex,
  selectSingleSong
}: Props) {
  const { dispatch } =
    usePlaylistContext();
  const [sortedData, setSortedData] = useState([]);
  const [startSort, setStartSort] = useState(false);
  const [endSort, setEndSort] = useState(false);
  useEffect(() => {
    if (scheduleData) {
      setSortedData(scheduleData);
    }
  }, [scheduleData]);

  const sortData = (sortby) => {
    let arr = [...sortedData];
    if (sortby === "playlist") {
      arr.sort((a, b) => {
        if (a?.localData?.selected_playlist_id < b?.localData?.selected_playlist_id) return -1;
        if (a?.localData?.selected_playlist_id > b?.localData?.selected_playlist_id) return 1;
        return 0;
      });
      setSortedData(arr);
    } else if (sortby === "date") {
      if (startSort) {
        arr.sort(
          (a, b) =>
            new Date(
              a?.localData?.start_time && a?.localData?.start_time === "null"
                ? new Date().setFullYear(2000)
                : a?.localData?.start_time
            ).getTime() -
            new Date(
              b?.localData?.start_time && b?.localData?.start_time === "null"
                ? new Date().setFullYear(2000)
                : b?.localData?.start_time
            ).getTime()
        );
        setStartSort(false);
      } else {
        arr.sort(
          (a, b) =>
            new Date(
              b?.localData?.start_time && b?.localData?.start_time === "null"
                ? new Date().setFullYear(2000)
                : b?.localData?.start_time
            ).getTime() -
            new Date(
              a?.localData?.start_time && a?.localData?.start_time === "null"
                ? new Date().setFullYear(2000)
                : a?.localData?.start_time
            ).getTime()
        );
        setStartSort(true);
      }
      setSortedData(arr);
    } else if (sortby === "endDate") {
      if (endSort) {
        arr.sort(
          (a, b) =>
            new Date(
              a?.localData?.end_time && a?.localData?.end_time === "null"
                ? new Date().setFullYear(2000)
                : a?.localData?.end_time
            ).getTime() -
            new Date(
              b?.localData?.end_time && b?.localData?.end_time === "null"
                ? new Date().setFullYear(2000)
                : b?.localData?.end_time
            ).getTime()
        );
        setEndSort(false);
      } else {
        arr.sort(
          (a, b) =>
            new Date(
              b?.localData?.end_time && b?.localData?.end_time === "null"
                ? new Date().setFullYear(2000)
                : b?.localData?.end_time
            ).getTime() -
            new Date(
              a?.localData?.end_time && a?.localData?.end_time === "null"
                ? new Date().setFullYear(2000)
                : a?.localData?.end_time
            ).getTime()
        );
        setEndSort(true);
      }
      setSortedData(arr);
    } else if (sortby === "uri") {
      arr.sort((a, b) => {
        if (a?.localData?.song_uri < b?.localData?.song_uri) return -1;
        if (a?.localData?.song_uri > b?.localData?.song_uri) return 1;
        return 0;
      });
      setSortedData(arr);
    }
  };
  // useEffect(() => {
  //   console.log(playlistState, 'playlistState')
  // }, [playlistState])
  const clickHandle = (
    url: any,
    id: any,
    image: any,
    name: any,
    artist: any,
    external: any
  ) => {
    // console.log(url,
    //   id,
    //   image,
    //   name,
    //   artist,
    //   external, 'dsadasdasdasda')
    if (url !== null) {
      dispatch({
        type: "UPDATE_SONGS_SEARCH_LIST",
        payload: id,
      });
      if (url === "" || url !== null) {
        dispatch({
          type: "SET_PREVIEW_SONG",
          payload: {
            url: url,
            id: id,
            name: name,
            artist: artist,
            image: image,
            external: external,
          },
        });
      }
    } else {

      window.open(`${external}`, "_blank");
    }
  };

  // useEffect(() => {
  //   console.log(sortedData, playlistState?.playlists, 'sortedData')
  // }, [sortedData, playlistState])
  const [ctrlHold, setCtrlHold] = useState(false)
  const [shiftHold, setShiftHold] = useState(false)
  useEffect(() => {
    window.addEventListener('keydown', (ev) => {
      // console.log(ev.keyCode)
      // KeyCode()
      // console.log(ev.keyCode)
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(true)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(true)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })

    window.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(false)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(false)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })
  }, [])

  const selectSongFn = (a, i) => {
    selectSongFunction({
      uri: a?.song_uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id, i)
  }

  const selectSingleSongFn = (a, i) => {
    selectSingleSong({
      uri: a?.rowNumber?.e?.track.uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id, i)
  }

  const rangeSongSelect = (a, toSelect) => {
    let idArr = []
    let arr = []
    sortedData?.map((b, eachSong) => {
      if ((eachSong >= selectedIndex && toSelect >= eachSong) || eachSong <= selectedIndex && toSelect <= eachSong) {

        idArr.push(b._id)
        arr.push({
          uri: b?.song_uri,
          positions: [
            b.lockPosition
              ? Number(b.lockPositionNumber)
              : eachSong + (currentPage - 1) * 50,
          ]
        })
      }
    })
    selectMultpleSongFunction(arr, idArr)
  }


  const PcTable = () => {
    return (
      <div className="tablePlayListDiv">
        <table className="tablePlayList">
          <thead>
            <tr>
              {/* {selectedSongs.length ? */}
              {/* <th>
                Select
              </th> */}
              {/* : null} */}
              <th className="seriesNumber">#</th>
              <th>
                <span
                  className="sortSpan"
                  onClick={() => {
                    sortData("uri");
                  }}
                >
                  URI
                </span>
                /
                <span
                  className="sortSpan"
                  onClick={() => {
                    sortData("playlist");
                  }}
                >
                  Playlist
                </span>
              </th>
              <th>Artist</th>
              <th>
                Start date
                <span
                  className="sortArrow"
                  onClick={() => {
                    sortData("date");
                  }}
                >
                  {startSort ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path>
                    </svg>
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                    </svg>
                  )}
                </span>
              </th>
              <th>
                End date
                <span
                  className="sortArrow"
                  onClick={() => {
                    sortData("endDate");
                  }}
                >
                  {endSort ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path>
                    </svg>
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                    </svg>
                  )}
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((a, i) => {
              const currentPlaylistContent = playlistState?.playlists?.find(
                (item) => {
                  if (a) {
                    if (a?.localData !== null) {
                      return item?.id === a?.localData?.selected_playlist_id ? true : false
                    }
                  }
                }
              );
              return (
                <tr className={(isSelected(a, selectedSongs, currentPage, i) ? 'selected' : null) + ' rowTable'} onClick={() => {
                  if (!bulkAction) {
                    setBulkAction(true)
                  }
                  if (ctrlHold) {
                    selectSongFn(a, i)
                    // setSelectedIndex(i)
                  }
                  if (shiftHold) {
                    rangeSongSelect(a, i)
                    // setSelectedIndex(i)
                  }
                  if (!ctrlHold && !shiftHold) {
                    selectSingleSongFn(a, i)
                  }
                }}>
                  {/* {selectedSongs.length ? */}
                  {/* <td>
                    <div className="checkbox" onClick={(e) => {
                      e?.stopPropagation()
                      console.log(a, 'asdasdasd')
                      if (shiftHold) {
                        rangeSongSelect(a, i)
                        // setSelectedIndex(i)
                      } else {
                        selectSongFn(a, i)
                      }
                    }}>

                      <>
                        {isSelected(a, selectedSongs, currentPage, i) ?
                          <CheckBoxIcon />
                          :
                          <CheckBoxOutlineBlankIcon />
                        }
                      </>
                    </div>
                  </td> */}
                  {/* : null} */}
                  <td>
                    <PopoverStyle>
                      <p style={{ marginRight: "10px", position: 'relative' }}>
                        {a.working ? (
                          <Popover
                            placement="right"
                            content={a.working ? <p>{a.working}</p> : ""}
                          >
                            <ErrorIcon
                              style={{ color: "orange", marginLeft: "-5px" }}
                            />
                          </Popover>
                        ) : (
                          <>
                            {a.previewUrl ? (
                              <PlayArrowIcon
                                style={{
                                  color: "white",
                                  position: "absolute"
                                  // marginLeft: "10px",
                                }}
                                className="icons1"
                              />
                            ) : (
                              <img
                                alt="spotifylogo"
                                src={'./../static/images/spotifyLogo.png'}
                                width={28}
                                height={28}
                                className="icons1"
                                style={{
                                  position: "absolute"
                                }}
                                onClick={() => {
                                  window.open(a.externalUrl, "_blank");
                                }}
                              />
                            )}
                            <span
                              className="icons2"
                              style={{
                                width: "",
                                // marginLeft: "-10px",
                              }}
                              onClick={() => {
                                // console.log(a)
                              }}
                            >
                              {i + 1}
                            </span>
                          </>
                        )}
                      </p>
                    </PopoverStyle>
                  </td>
                  <td style={{ width: "40%" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '10px' }}>
                        <img
                          className="table-list-image"
                          alt="thumbnail"
                          src={a?.album?.images[0]?.url}
                          style={{ aspectRatio: "1" }}
                          onClick={() => {
                            clickHandle(
                              a?.preview_url,
                              a?.id,
                              a?.album?.images[0]?.url,
                              a?.name,
                              a?.artists[0]?.name,
                              a?.external_urls?.spotify
                            );
                          }}

                        />
                      </div>
                      <div onClick={() => {
                        clickHandle(
                          a?.preview_url,
                          a?.id,
                          a?.album?.images[0]?.url,
                          a?.name,
                          a?.artists[0]?.name,
                          a?.external_urls?.spotify
                        );
                      }}>
                        {/* <h3>
                          <span className="title">URI:</span>
                          {a.uri || ""}
                        </h3> */}
                        <h3>
                          {truncateText(a.name, 23) || ""}
                        </h3>
                        <h3>
                          <span className="title">
                            playlist:
                          </span>
                          {truncateText(currentPlaylistContent?.name, 25) || "Unknown"}
                        </h3>
                      </div>
                    </div>
                  </td>
                  <td onClick={() => {
                    clickHandle(
                      a?.preview_url,
                      a?.id,
                      a?.album?.images[0]?.url,
                      a?.name,
                      a?.artists[0]?.name,
                      a?.external_urls?.spotify
                    );
                  }}>
                    <h3>{truncateText(a?.artists[0]?.name,18)}</h3>
                  </td>
                  <td onClick={() => {
                    clickHandle(
                      a?.preview_url,
                      a?.id,
                      a?.album?.images[0]?.url,
                      a?.name,
                      a?.artists[0]?.name,
                      a?.external_urls?.spotify
                    );
                  }}>
                    <h3>
                      {moment(a?.localData?.start_time).format("DD-MM-YYYY hh:mm A") !==
                        "Invalid date"
                        ? moment(a?.localData?.start_time).format("llll")
                        : "-"}
                    </h3>
                  </td>
                  <td onClick={() => {
                    clickHandle(
                      a?.preview_url,
                      a?.id,
                      a?.album?.images[0]?.url,
                      a?.name,
                      a?.artists[0]?.name,
                      a?.external_urls?.spotify
                    );
                  }}>
                    <h3>
                      {moment(a?.localData?.end_time).format("DD-MM-YYYY hh:mm A") !==
                        "Invalid date"
                        ? moment(a?.localData?.end_time).format("llll")
                        : "-"}
                    </h3>
                    {/* <h3>Sunday, January 30, 2022</h3> */}
                  </td>
                  <td>
                    <div className="bottom-dropdown-td">
                      {a.notes ? (
                        <Popover
                          content={<p className="hanker2">{a?.localData?.notes}</p>}
                          placement="topLeft"
                          title="Notes"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <CalenderIcon />
                          </div>
                        </Popover>
                      ) : (
                        ""
                      )}
                      <TablesStyle>
                        <div className="clay" style={{ display: "flex" }}>
                          <Dropdown
                            overlay={() => menu(a._id, a)}
                            placement={
                              i > sortedData?.length - 5
                                ? "topLeft"
                                : "bottomLeft"
                            }
                          // open
                          >
                            <span
                              onClick={(e) => e.preventDefault()}
                              style={{ cursor: "pointer" }}
                            >
                              <Space>
                                <VerticalDots />
                              </Space>
                            </span>
                          </Dropdown>
                        </div>
                      </TablesStyle>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const MobTable = () => {
    return (
      <div className="mobTablePlaylist">
        <div className="innerMobTablePlaylist">
          {sortedData.map((a, i) => {
            const currentPlaylistContent = playlistState?.playlists?.find(
              (item) => {
                if (a) {
                  if (a?.localData !== null) {
                    return item?.id === a?.localData?.selected_playlist_id ? true : false
                  }
                }
              }
            );
            return (
              <div className="rowMob">
                <div className="numberAndIcon">
                  {a.working ? (
                    <Popover
                      placement="right"
                      content={a.working ? <p>{a.working}</p> : ""}
                    >
                      <ErrorIcon
                        style={{ color: "orange", marginLeft: "-5px" }}
                      />
                    </Popover>
                  ) : (
                    i + 1
                  )}
                </div>

                <div className="songDetail">
                  <div style={{
                    display: 'flex', alignItems: 'center',
                    width: "100%",
                    overflow: 'hidden',
                    textOverflow: "ellipsis"
                  }}>
                    <div style={{ marginRight: '10px' }}>
                      <img
                        className="table-list-image"
                        alt="thumbnail"
                        src={a?.album?.images[0]?.url}
                        style={{ aspectRatio: "1", width: '58px', height: '58px' }}
                        onClick={() => {
                          clickHandle(
                            a?.preview_url,
                            a?.id,
                            a?.album?.images[0]?.url,
                            a?.name,
                            a?.artists[0]?.name,
                            a?.external_urls?.spotify
                          );
                        }}
                        width="40"
                        height="40"
                      />
                    </div>
                    <div onClick={() => {
                      clickHandle(
                        a?.preview_url,
                        a?.id,
                        a?.album?.images[0]?.url,
                        a?.name,
                        a?.artists[0]?.name,
                        a?.external_urls?.spotify
                      );
                    }}>
                      {/* <h3>
                        <span className="title">URI:</span>
                        {a.uri || ""}
                      </h3> */}
                      <h3>
                        {a.name || ""}
                      </h3>
                      <h3>
                        <span className="title">
                          playlist:
                        </span>
                        {currentPlaylistContent?.name || "Unknown"}
                      </h3>
                    </div>
                  </div>
                  <div className="songTimeDetail">
                    <table className="startEndDate">
                      <tbody>
                        <tr>
                          <td>
                            <p>Start date:</p>
                          </td>
                          <td>
                            <h3 className="startEndTime">
                              {moment(a.start_time).format(
                                "DD-MM-YYYY hh:mm A"
                              ) !== "Invalid date"
                                ? moment(a.start_time).format("llll")
                                : "-"}
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>End date:</p>
                          </td>
                          <td>
                            <h3 className="startEndTime">
                              {moment(a.end_time).format(
                                "DD-MM-YYYY hh:mm A"
                              ) !== "Invalid date"
                                ? moment(a.end_time).format("llll")
                                : "-"}
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="actionTools">
                  {a.notes ? (
                    <Popover
                      content={<p className="hanker2">{a.notes}</p>}
                      placement="topLeft"
                      title="Notes"
                    >
                      <div style={{ cursor: "pointer" }}>
                        <CalenderIcon />
                      </div>
                    </Popover>
                  ) : (
                    ""
                  )}
                  <Dropdown
                    overlay={() => menu(a._id, a)}
                    trigger={["click"]}
                    // open
                    placement="bottomRight"
                  >
                    <span onClick={(e) => e.preventDefault()}>
                      <Space>
                        <VerticalDots />
                      </Space>
                    </span>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </div>
      </div >
    );
  };

  return (
    <FuturePlaylistSongTableStyle>
      <PcTable />
      <MobTable />
    </FuturePlaylistSongTableStyle>
  );
}

export default ScheduledPlaylistSongTable;

