import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { getstripePrices } from "../../utils";
import PricingPlanStyle from "./PricingPlan.style";
interface PricingPlanProps {
  blob?: boolean;
  next?: (id: string, type: string, duration: string, currency: string) => void;
  setSignUpModalIsOpen?: (bool) => void;
  setPlanStatus?: (string) => void;
  setPlanId?: (string) => void;
  setPlanPaid?: (string) => void;
  planStatus?: string;
  planId?: string;
  planPaid?: string;
  currentPlan?: string;
  defaultCurrency?: string;
  selectedCurrency?: string;
  setdefaultCurrency?: (string) => void;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  blob,
  next,
  setSignUpModalIsOpen,
  setPlanStatus,
  setPlanId,
  setPlanPaid,
  planStatus,
  planId,
  planPaid,
  currentPlan,
  setdefaultCurrency,
  selectedCurrency,
}) => {
  const defaultCurrency = new URL(window.location as any).searchParams.get(
    "defaultCurrency"
  );

  const [currency, setcurrency] = useState(defaultCurrency || "USD");
  useEffect(() => {
    if (["USD", "GBP", "EUR"].includes(selectedCurrency)) {
      setcurrency(selectedCurrency);
    }
  }, [selectedCurrency]);
  const [pricesList, setpricesList] = useState([]);

  const pricing = {
    packages: [
      {
        price: "Free",
        forTime: "",
        currence: "",
        name: "Basic Package",
        save: "",
        detail: () => {
          return <p>1 Spotify or Apple Music profile</p>;
        },
        id: process.env.REACT_APP_STRIPE_FREE,
        paid: "free",
        onClick: (val) =>
          next(process.env.REACT_APP_STRIPE_FREE, "Free", "year", val),
      },
      {
        price: "16",
        forTime: "/ Month ",
        currence: "€",
        name: "Pro Package",
        save: "Billed Annually, saving 15% per year",
        detail: () => {
          return <p>2 Spotify or Apple Music profiles</p>;
        },
        id: process.env.REACT_APP_STRIPE_PRO,
        paid: "paid",
        onClick: (val) =>
          next(process.env.REACT_APP_STRIPE_PRO, "Pro", "year", val),
      },
      {
        price: "75",
        forTime: "/ Month ",
        currence: "€",
        name: "Unlimited Package",
        save: "Billed Annually, saving 15% per year",
        detail: () => {
          return <p>Unlimited Spotify and Apple Music profiles</p>;
        },
        id: process.env.REACT_APP_STRIPE_UNLIMITED,
        paid: "paid",
        onClick: (val) =>
          next(
            process.env.REACT_APP_STRIPE_UNLIMITED,
            "Unlimited",
            "year",
            val
          ),
      },
    ],
    features: [
      {
        featureName: "Pre-release feature",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Add or delete songs",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Create, edit or delete playlists",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Add notes",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Song popularity index",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Schedule start dates",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Schedule end dates",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Bulk mode",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Select multiple playlists at once",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Show Songplace only",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Search by pasting link",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Priority support",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Fix song positions",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Unlimited refresh playlists",
        availibilty: ["Unlimited Package"],
      },
    ],
  };
  const pricingMonthly = {
    packages: [
      {
        price: "Free",
        forTime: "",
        currence: "",
        name: "Basic Package",
        detail: () => {
          return <p>1 Spotify or Apple Music profile</p>;
        },
        id: process.env.REACT_APP_STRIPE_FREE,
        paid: "free",
        onClick: (val) =>
          next(process.env.REACT_APP_STRIPE_FREE, "Free", "month", val),
      },
      {
        price: "19",
        forTime: "/ Month",
        currence: "€",
        name: "Pro Package",
        save: "Get 15% off on yearly subscriptions.",
        detail: () => {
          return <p>2 Spotify or Apple Music profiles</p>;
        },
        id: process.env.REACT_APP_STRIPE_PRO_MONTHLY,
        paid: "paid",
        onClick: (val) =>
          next(process.env.REACT_APP_STRIPE_PRO_MONTHLY, "Pro", "month", val),
      },
      {
        price: "89",
        forTime: "/ Month",
        currence: "€",
        name: "Unlimited Package",
        save: "Get 15% off on yearly subscriptions.",
        detail: () => {
          return <p>Unlimited Spotify and Apple Music profiles</p>;
        },
        id: process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY,
        paid: "paid",
        onClick: (val) =>
          next(
            process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY,
            "Unlimited",
            "month",
            val
          ),
      },
    ],
    features: [
      {
        featureName: "Pre-release feature",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Add or delete songs",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Create, edit or delete playlists",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Add notes",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Song popularity index",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Schedule start dates",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Schedule end dates",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Bulk mode",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Select multiple playlists at once",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Show Songplace only",
        availibilty: ["Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Search by pasting link",
        availibilty: ["Basic Package", "Pro Package", "Unlimited Package"],
      },
      {
        featureName: "Priority support",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Fix song positions",
        availibilty: ["Unlimited Package"],
      },
      {
        featureName: "Unlimited refresh playlists",
        availibilty: ["Unlimited Package"],
      },
    ],
  };
  const [activePackages, setActivePackages] = useState({
    packages: [],
    features: [],
  });
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // @ts-ignore        
    setActivePackages(pricing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);     


  const switchActive = (checked: boolean) => {
    if (checked) {
      // @ts-ignore
      setActivePackages(pricing);
    } else {
      // @ts-ignore
      setActivePackages(pricingMonthly);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let STRIPE_PRO_MONTHLY = process.env.REACT_APP_STRIPE_PRO_MONTHLY;
        let STRIPE_PRO = process.env.REACT_APP_STRIPE_PRO;
        let STRIPE_UNLIMITED_MONTHLY =
          process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY;
        let STRIPE_UNLIMITED = process.env.REACT_APP_STRIPE_UNLIMITED;
        let prices = await getstripePrices();
        let data = prices.data.data.filter(
          (obj) =>
            obj.id === STRIPE_PRO_MONTHLY ||
            obj.id === STRIPE_PRO ||
            obj.id === STRIPE_UNLIMITED_MONTHLY ||
            obj.id === STRIPE_UNLIMITED
        );
        setpricesList(data);
      } catch (err) {
      }
    })();
  }, []);
  const items = [
    {
      label: "EUR",
      key: "EUR",
    },
    {
      label: "USD",
      key: "USD",
    },
    {
      label: "GBP",
      key: "GBP",
    },
  ];
  const currencyIcons = {
    GBP: {
      value: "£",
    },
    USD: {
      value: "$",
    },
    EUR: {
      value: "€",
    },
  };

  const CurrencyDropdown = () => (
    <Dropdown
    className="currnecyropDown"
    overlayClassName="currnecyropDownOverlay"
      //@ts-ignore
      menu={{
        items,
        selectable: !selectedCurrency,
        defaultSelectedKeys: [currency],
        onSelect: (e) => {

          setcurrency(e.key);
          if (setdefaultCurrency) setdefaultCurrency(e.key);


        },
      }}
      // overlayStyle={{
      //   zIndex:1000000000000
      // }}
      trigger={["click"]}
    >
      <div style={{ cursor: "pointer" }} onClick={(e) => e.preventDefault()}>
        <Space>
          {currency}
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
  return (
    <div className="pricing-container" id="Pricing">
      <h1>Pricing Plan</h1>
      <p className="main-para">
        Get the #1 playlist management tool at the best price. Easily switch
        plans or cancel any time.
      </p>
      <div className="annualMonth">
        <div className="div1">
          <p>Monthly</p>
          <Switch defaultChecked onChange={switchActive} aria-labelledby="name" />
          <p style={{ marginLeft: "10px" }}>Annually</p>
        </div>
        <br />
        <div className="div2">
          <p>
            <b>Select currency :</b>
          </p>
          {["USD", "GBP", "EUR"].includes(selectedCurrency) ?
            currency
            :
            <div>
              <CurrencyDropdown />
            </div>
          }
        </div>
      </div>

      <PricingPlanStyle>
        {activePackages.packages.map((a, i) => {
          let price = pricesList.filter((obj) => obj.id === a.id)[0];
          return (
            <div
              className={`pricingPlan ${a.name === "Pro Package" ? "proPlan" : ""
                }`}
            >
              <div className="innerPricingPlan">
                <div className="packageName">
                  <h3>
                    <b>{a.name}</b>
                  </h3>
                  <p>
                    <b>{a.detail()}</b>
                  </p>
                </div>
                <div className="packagePrice">
                  <h1>
                    {a.currence && currencyIcons[currency]?.value}{" "}
                    {price?.currency_options[currency?.toLocaleLowerCase()]
                      ? String(price.id) === process.env.REACT_APP_STRIPE_PRO ||
                        String(price.id) === process.env.REACT_APP_STRIPE_UNLIMITED
                        ? Math.round(
                          Math.round(
                            price?.currency_options[
                              currency?.toLocaleLowerCase()
                            ].unit_amount / 100
                          ) / 12
                        )
                        : Math.round(
                          price?.currency_options[
                            currency?.toLocaleLowerCase()
                          ].unit_amount / 100
                        )
                      : a.price}
                    {/* {a.currence} {a.price} */}
                  </h1>
                  <p> {a.forTime}</p>
                  <br />
                </div>
                <div>
                  <p className="belowPrice">{a.save}</p>
                </div>
                <hr className="priceLine" />
                <div className="features">
                  {activePackages.features.map((b, j) => (
                    <div className="feat">
                      {b.availibilty.includes(a.name) ? (
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#1ED760" />
                          <path
                            d="M13.5382 5.61448C13.471 5.54664 13.3909 5.4928 13.3027 5.45605C13.2145 5.41931 13.1199 5.40039 13.0244 5.40039C12.9288 5.40039 12.8342 5.41931 12.746 5.45605C12.6578 5.4928 12.5778 5.54664 12.5105 5.61448L7.11848 11.0137L4.85311 8.74113C4.78325 8.67365 4.70078 8.62058 4.61042 8.58497C4.52005 8.54936 4.42356 8.5319 4.32644 8.53358C4.22933 8.53526 4.1335 8.55605 4.04442 8.59476C3.95534 8.63348 3.87476 8.68936 3.80727 8.75922C3.73979 8.82908 3.68673 8.91155 3.65112 9.00191C3.6155 9.09228 3.59804 9.18877 3.59972 9.28589C3.6014 9.383 3.62219 9.47884 3.66091 9.56792C3.69963 9.65699 3.75551 9.73758 3.82537 9.80506L6.60461 12.5843C6.6719 12.6521 6.75194 12.706 6.84014 12.7427C6.92834 12.7795 7.02294 12.7984 7.11848 12.7984C7.21403 12.7984 7.30863 12.7795 7.39682 12.7427C7.48502 12.706 7.56507 12.6521 7.63235 12.5843L13.5382 6.67841C13.6117 6.61063 13.6703 6.52838 13.7104 6.43682C13.7505 6.34527 13.7713 6.2464 13.7713 6.14644C13.7713 6.04649 13.7505 5.94762 13.7104 5.85607C13.6703 5.76451 13.6117 5.68225 13.5382 5.61448Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="9" cy="9" r="9" fill="#EA4335" />
                          <path
                            d="M5 13L13 5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13 13L5 5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}

                      <p>{b.featureName}</p>
                    </div>
                  ))}
                </div>
                <button
                  className="getStarted"
                  onClick={() => {
                    if (setSignUpModalIsOpen) {
                      setSignUpModalIsOpen(true);
                      setPlanStatus("planChoosen");
                      setPlanId(a.id);
                      setPlanPaid(a.paid);
                    } else {
                      a.onClick(currency);
                    }
                  }}
                >
                  {`Get Started`}
                </button>
              </div>
            </div>
          );
        })}
      </PricingPlanStyle>

      {blob && <img alt="pricingplanblob.png" src="./static/pricingplanblob.webp" className="pricingplanblob" />}
    </div>
  );
};

export default PricingPlan;
