import React, { useEffect, useState } from 'react'
import { Popover } from 'antd';
import moment from 'moment';
import { AddToPlayListModalStyle } from '../AddToPlayListModal/AddToPlayListModal.style';
import { copyToClipboard } from '../../utils';
import { useLoginContext } from '../../context';

function ShareModal({
    isModalVisible,
    setIsModalVisible,
    editType,
    ViewDetailData,
    setViewDetailData,
    currentPlaylistData,
    PlaylistRefreshRecord,
    showCopyUrl,
    scheduleSong

}) {
    const [notes, setNotes] = useState("");
    const [copied, setCopied] = useState(false)
    const { state: loginState, dispatch: loginDispatch } = useLoginContext();
    const [originalDate, setOriginalDate] = useState('')

    const handleOk = () => {
        setIsModalVisible(false);
        if (setViewDetailData) {
            setViewDetailData("");
        }
    };
    const handleCancel = (e: any) => {
        e.stopPropagation();
        setIsModalVisible(false);
        if (setViewDetailData) {
            setViewDetailData("");
        }
    };
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (ViewDetailData?.notes) {
            setNotes(ViewDetailData.notes);
        } else {
            setNotes("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ViewDetailData?.notes]);
    useEffect(() => {
        // @ts-ignore
        const url = new URL(window.location);
        const originalDate = url.searchParams.get('originalDate');
        if (scheduleSong) {

            setOriginalDate(PlaylistRefreshRecord?.length
                ? PlaylistRefreshRecord[0].addeddUri
                    ?
                    moment(
                        JSON.parse(
                            PlaylistRefreshRecord[0]?.addeddUri
                        ).filter(
                            (obj) => obj.uri === ViewDetailData?.song_uri
                        ).length ? JSON.parse(
                            PlaylistRefreshRecord[0]?.addeddUri
                        ).filter(
                            (obj) => obj.uri === ViewDetailData?.song_uri
                        )[0]?.date : ViewDetailData?.started_time
                    ).format("DD/MM/YYYY")
                    : moment(ViewDetailData?.started_time).format("DD/MM/YYYY")
                : ViewDetailData?.started_time ?moment(ViewDetailData?.started_time).format("DD/MM/YYYY") : moment(originalDate).format("DD/MM/YYYY"))
        } else {
            setOriginalDate(PlaylistRefreshRecord?.length
                ? PlaylistRefreshRecord[0].addeddUri
                    ?
                    moment(
                        JSON.parse(
                            PlaylistRefreshRecord[0]?.addeddUri
                        ).filter(
                            (obj) => obj.uri === ViewDetailData?.track?.uri
                        ).length ? JSON.parse(
                            PlaylistRefreshRecord[0]?.addeddUri
                        ).filter(
                            (obj) => obj.uri === ViewDetailData?.track?.uri
                        )[0]?.date : ViewDetailData?.added_at
                    ).format("DD/MM/YYYY")
                    : moment(ViewDetailData?.added_at).format("DD/MM/YYYY")
                : ViewDetailData?.added_at ? moment(ViewDetailData?.added_at).format("DD/MM/YYYY") : moment(originalDate).format("DD/MM/YYYY"))
        }
    }, [PlaylistRefreshRecord, scheduleSong])
    return (
        <AddToPlayListModalStyle
            visible={isModalVisible}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}
            className={`${editType}`}
        >
            <>
                <div className="viewDetailInner">
                    <h1 className="heading" onClick={() => {
                        console.log(ViewDetailData)
                    }}>Song details</h1>
                    <div className="albumDiv">
                        <img
                            alt={ViewDetailData?.track?.name || ViewDetailData?.name}
                            className="albumPic"
                            src={ViewDetailData?.track?.album?.images[0]?.url || ViewDetailData?.album?.images[0]?.url}
                        />
                        <div className="albumDetail">
                            <h3>{ViewDetailData?.track?.name || ViewDetailData?.name}</h3>
                            {ViewDetailData?.artists || ViewDetailData?.track?.artists[0]?.name ?
                                <p>{ViewDetailData?.track?.artists[0]?.name || ViewDetailData?.artists[0]?.name}</p>
                                : null}
                        </div>
                    </div>
                    <div className="detailBoxes1">
                        <div className="box">
                            <p>Song artist</p>
                            {ViewDetailData?.artists || ViewDetailData?.track?.artists[0]?.name ?
                                <h4>{ViewDetailData?.track?.artists[0]?.name || ViewDetailData?.artists[0]?.name}</h4>
                                : null}
                        </div>
                        <div className="box">
                            <p>Song position</p>
                            <h4>{Number(ViewDetailData?.position) > 0 ? Number(ViewDetailData?.position) + 1 : "-"}</h4>
                        </div>
                        <div className="box">
                            <p
                            >
                                Playlist
                            </p>
                            <Popover
                                content={<p>{currentPlaylistData?.name}</p>}
                                placement="topLeft"
                            >
                                <h4 className="playlistNameViewDetail">
                                    {currentPlaylistData?.name}
                                </h4>
                            </Popover>
                        </div>
                    </div>
                    <div className="detailBoxes2">
                        <div className="box">
                            <p>Original Start date</p>
                            <h4>
                                {originalDate}
                                {/* {scheduleSong ?
                                    <>

                                        {PlaylistRefreshRecord?.length
                                            ? PlaylistRefreshRecord[0].addeddUri
                                                ?
                                                moment(
                                                    JSON.parse(
                                                        PlaylistRefreshRecord[0]?.addeddUri
                                                    ).filter(
                                                        (obj) => obj.uri === ViewDetailData?.song_uri
                                                    ).length ? JSON.parse(
                                                        PlaylistRefreshRecord[0]?.addeddUri
                                                    ).filter(
                                                        (obj) => obj.uri === ViewDetailData?.song_uri
                                                    )[0]?.date : ViewDetailData?.started_time
                                                ).format("DD/MM/YYYY")
                                                : moment(ViewDetailData?.started_time).format("DD/MM/YYYY")
                                            : moment(ViewDetailData?.started_time).format("DD/MM/YYYY")}
                                    </>
                                    :
                                    <>

                                        {PlaylistRefreshRecord?.length
                                            ? PlaylistRefreshRecord[0].addeddUri
                                                ?
                                                moment(
                                                    JSON.parse(
                                                        PlaylistRefreshRecord[0]?.addeddUri
                                                    ).filter(
                                                        (obj) => obj.uri === ViewDetailData?.track?.uri
                                                    ).length ? JSON.parse(
                                                        PlaylistRefreshRecord[0]?.addeddUri
                                                    ).filter(
                                                        (obj) => obj.uri === ViewDetailData?.track?.uri
                                                    )[0]?.date : ViewDetailData?.added_at
                                                ).format("DD/MM/YYYY")
                                                : moment(ViewDetailData?.added_at).format("DD/MM/YYYY")
                                            : moment(ViewDetailData?.added_at).format("DD/MM/YYYY")}
                                    </>} */}
                            </h4>
                        </div>
                        <div className="box">
                            <p>End date</p>
                            <h4>
                                {moment(ViewDetailData?.endTime).format("DD/MM/YYYY") !==
                                    "Invalid date"
                                    ? moment(ViewDetailData?.endTime).format("DD/MM/YYYY")
                                    : "-"}
                            </h4>
                        </div>
                        {/* <div className="box">
                            <p onClick={() => {
                                console.log(PlaylistRefreshRecord, 'PlaylistRefreshRecord')
                            }}>Last Refresh</p>
                            <h4>
                                {PlaylistRefreshRecord?.length
                                    ? moment(PlaylistRefreshRecord[0].date || Number(PlaylistRefreshRecord[0].refreshTime)).format("DD/MM/YYYY HH:mm:ss")
                                    : "-"}
                            </h4>
                        </div> */}
                        <div className="box">
                            <p onClick={() => {
                                console.log(ViewDetailData, 'PlaylistRefreshRecord')
                            }}>Song popularity</p>
                            <h4>
                                {ViewDetailData?.popularity}
                            </h4>
                        </div>
                    </div>
                    {notes && (
                        <div className="note">
                            <h3>Note</h3>
                            <p>{notes}</p>
                        </div>
                    )}
                    {showCopyUrl && (


                        <div className="note songURlCopy">
                            <p onClick={() => {
                                console.log(loginState, 'loginStateloginState')
                            }}>{window?.location?.origin + "/track-detail?uri=" + (ViewDetailData?.song_uri || ViewDetailData?.songUri) + "&playlistId=" + (ViewDetailData?.selected_playlist_id || ViewDetailData?.playlist) + '&id=' + ViewDetailData?.id + '&ref=' + loginState.refreshToken + '&originalDate=' + originalDate}</p>
                            <Popover
                                overlayClassName='copyPop'
                                content={<p>{copied ? 'Copied' : 'Copy'}</p>}
                                placement="topLeft"
                            >
                                <button className='copyUrlSong' onClick={() => {
                                    // console.log(ViewDetailData, 'ViewDetailData')
                                    setCopied(true)
                                    copyToClipboard(window?.location?.origin + "/track-detail?uri=" + ViewDetailData?.song_uri + "&playlistId=" + ViewDetailData?.selected_playlist_id + '&id=' + ViewDetailData?.id + '&ref=' + loginState.refreshToken + '&position=' + ViewDetailData?.position + '&originalDate=' + originalDate)
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 1000)
                                }}>Copy</button>
                            </Popover>
                        </div>
                    )}
                </div>
            </>
        </AddToPlayListModalStyle >
    )
}

export default ShareModal