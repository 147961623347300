import { DatePicker, Input, Popconfirm, Popover, Select, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BulkModeCTA1, Songplace } from "..";
import {
  BackupIcon,
  DeleteIcon,
  FixIcon,
  PlayListNew,
  RestoreIcon,
  Star,
} from "../../assets";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { FilledButtonStyle } from "../../styles/common.style";
import {
  addItemToStorage,
  addSongToPlaylist,
  dateFormationDDMMYY,
  deleteAllRefresh,
  getRefreshedToken,
  getRefreshTime,
  refreshPlaylist,
  timeAmPM,
  truncateText,
} from "../../utils";
import {
  AddToPlayListModalStyle,
  ScheduleModalStyle,
} from "../AddToPlayListModal/AddToPlayListModal.style";
import PlanToast from "../PlanToast/PlanToast";
import { PlayListBannerStyle } from "./PlayListBanner.style";
import SyncModal from "./SyncModal";
import RestoreModal from "./RestoreModal";
import BulkActionToggle from "../BulkModeCTA/BulkActionToggle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Spinner from "../Spinner/Spinner";
import { BASE_URL } from "../../constants/api-urls";
import moment from "moment";
import DeleteModal from "../Subscriptions/CancelSubscription";
import ScheduleRefreshModal from "./ScheduleRefreshModal";
import ScheduleFixModal from "./ScheduleFixModal";
import { toast } from "react-toastify";
interface PlayListBannerProps {
  title?: string;
  desc?: string;
  totalSongs?: string;
  image?: string;
  token?: string;
  songs?: any[];
  currentPage?: number;
  setAddToPlaylistModal?: any;
  userId?: string;
  handleRefresh?: (
    values: any,
    deleteAllRefresh: any,
    refreshPlaylist: any,
    token: any,
    songs: any,
    handleRefresh: any
  ) => void;
  setIsModalVisible: (boolean) => void;
  isModalVisible?: boolean;
  currentPlaylistData?: any;
  hideFullPlaylistMode?: any;
  fixed?: any;
  refreshPlaylistFunction?: any;
  currentPlaylist?: any;
  isRefreshScheduled?;
  syncdatafromspotify?: any;
  restorePlaylsitSongs?: any;
  PlaylistRefreshRecord?: any;
  bulkAction?: any;
  setBulkAction?: any;
  showModalDeleteSelected?: any;
  selectedSongs?: any;
  refreshScheduleData?: any;
  getScheduleRefreshData?: any;
  getScheduleFixData?: any;
  fixScheduleFData?: any;
}

const PlayListBanner = (props: PlayListBannerProps) => {
  const { playlistId } = useParams();
  const { state } = usePlaylistContext();
  const {
    title,
    totalSongs,
    image,
    token,
    songs,
    userId,
    setAddToPlaylistModal,
    desc,
  } = props;
  // const { state } = useLoginContext();
  const { state: playlistState } = usePlaylistContext();
  const selectRefreshFreq = {
    value: "Once(Manually)",
    frequency: "spotifyPlaylistRefreshOperationOnce",
  };
  const { dispatch: newDispatch } = usePlaylistContext();
  const [datePickeropen, setdatePickeropen] = useState(false);
  const [datePickeropenFix, setdatePickeropenFix] = useState(false);
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();

  const [versionRestore, setVersionRestore] = useState(0);
  const [saveRestore, setSaveRestore] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [name, setname] = useState(title);
  const [syncModal, setsyncModal] = useState(false);
  const [restoreModal, setrestoreModal] = useState(false);
  const [description, setdescription] = useState("");
  const [updatedDesc, setUpdatedDesc] = useState("");
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isRestoreModalVisible, setisRestoreModalVisible] = useState(false);
  const [restoreModalLoader, setRestoreModalLoader] = useState(false);
  const [scheduleRefreshModal, setScheduleRefreshModal] = useState(false);
  const [scheduleFixModal, setScheduleFixModal] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePickerFix, setOpenDatePickerFix] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpenFixDelete, setIsModalOpenFixDelete] = useState(false);
  const showModalDelete = () => {
    setIsModalOpenDelete(true);
    handleScheduleModalCancel();
  };

  const handleOkDelete = () => {
    setIsModalOpenDelete(false);
    deletePlaylistRefresh();
  };

  const handleCancelDelete = () => {
    setIsModalOpenDelete(false);
    handleScheduleModalShow();
  };

  const showModalFixDelete = () => {
    setIsModalOpenFixDelete(true);
    handleScheduleFixModalCancel();
  };

  const handleOkFixDelete = () => {
    setIsModalOpenFixDelete(false);
    deletePlaylistFix();
  };

  const handleCancelFixDelete = () => {
    setIsModalOpenFixDelete(false);
    handleScheduleFixModalShow();
  };

  const [isEditPlaylistModalVisible, setIsEditPlaylistModalVisible] =
    useState(false);
  useEffect(() => {
    setname(title);
    setdescription(desc);
  }, [title, desc, playlistId, isEditPlaylistModalVisible]);
  useEffect(() => {
    setTheme(playlistState?.isWholeTheme === false ? "dark" : "light");
  }, [playlistState]);

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  const handleOk = (values) => {
    addSongToPlaylist(
      Array.isArray(values.playlistId)
        ? values.playlistId
        : [values.playlistId],
      values.frequency,
      token,
      loginState.refreshToken,
      userId,
      0,
      "2023-08-08",
      "2023-08-08",
      null,
      ""
    );
    props.setIsModalVisible(false);
  };

  const handleScheduleModalShow = () => {
    setScheduleRefreshModal(true);
  };

  const handleScheduleModalCancel = () => {
    setScheduleRefreshModal(false);
  };
  const handleScheduleModalhandleOk = (values) => {
    setScheduleRefreshModal(false);
  };

  const handleScheduleFixModalShow = () => {
    setScheduleFixModal(true);
  };

  const handleScheduleFixModalCancel = () => {
    setScheduleFixModal(false);
  };
  const handleScheduleFixModalhandleOk = (values) => {
    setScheduleFixModal(false);
  };
  const array = [];
  playlistState?.playlists &&
    playlistState?.playlists.length > 0 &&
    playlistState?.playlists.map((e, index) => {
      const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
      if (
        e?.owner?.display_name !== spotifyUser?.username &&
        e?.id === playlistId
      ) {
        array.push(e?.owner?.display_name);
      }
    });

  const playListGet = async (call?: any) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=8&offset=3`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then(async (genreResponse) => {
        newDispatch({
          type: "INITIALIZE_PLAYLISTS",
          payload: genreResponse?.data?.items,
        });
        if (call) {
          // await timeout(3000);
          // window.location.reload();
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => {});
        }
      });
  };

  const handleEditPlaylistDetails = async (values) => {
    let obj =
      values?.playlistname && values.playlistDescription
        ? JSON.stringify({
            name: values?.playlistname,
            description: values.playlistDescription,
          })
        : values?.playlistname
        ? JSON.stringify({
            name: values?.playlistname,
          })
        : values?.playlistDescription
        ? JSON.stringify({
            description: values.playlistDescription,
          })
        : {};
    try {
      axios(`https://api.spotify.com/v1/playlists/${playlistId}`, {
        headers: {
          Authorization: "Bearer " + loginState.token,
        },
        data: obj,
        method: "PUT",
      }).then(() => {
        setUpdatedDesc(values.playlistDescription);
        setIsEditPlaylistModalVisible(false);
        playListGet(true);
        window.location.reload();
      });
    } catch (error) {}
  };
  const [scheduleType, setScheduleType] = useState("");
  const [scheduleFixType, setScheduleFixType] = useState("");
  const [scheduleDateTime, setScheduleDateTime] = useState(new Date());
  const [scheduleFixDateTime, setScheduleFixDateTime] = useState(new Date());
  const [scheduleLaoder, setScheduleLoader] = useState(false);
  const [scheduleLaoderFix, setScheduleLoaderFix] = useState(false);
  const [scheduleDateTimeDatePicker, setScheduleDateTimeDatePicker] =
    useState<any>();
  const [scheduleDateFixTimeDatePicker, setScheduleFixDateTimeDatePicker] =
    useState<any>();
  const ScheduleRefreshFn = () => {
    setScheduleLoader(true);
    console.log(props.refreshScheduleData, scheduleDateTime);
    if (props.refreshScheduleData) {
      let obj = {
        nextrefreshtTime: props.refreshScheduleData.nextrefreshtTime,
        refreshTime: props.refreshScheduleData.refreshTime,
        type: scheduleType,
        accessToken: loginState.refreshToken,
        backup: saveRestore,
      };
      console.log(scheduleDateTimeDatePicker, "scheduleDateTimeDatePicker");
      if (scheduleDateTimeDatePicker) {
        // @ts-ignore
        // refreshValue,
        obj.refreshValue = new Date(scheduleDateTimeDatePicker).getTime();
      }
      axios(`${BASE_URL}/updateRefreshlogs/${props.refreshScheduleData._id}`, {
        // headers: {
        //   Authorization: "Bearer " + loginState.token,
        // },
        data: obj,
        method: "put",
      }).then((res) => {
        setScheduleLoader(false);
        handleScheduleModalCancel();
        props.getScheduleRefreshData(playlistId);
        window.location.reload();
      });
    } else {
      if (scheduleType) {
        let obj = {
          playlist_id: playlistId,
          accessToken: loginState.refreshToken,
          type: scheduleType,
          backup: saveRestore,
          refreshTime: scheduleDateTimeDatePicker,
        };
        axios(`${BASE_URL}/addRefreshlogs`, {
          // headers: {
          //   Authorization: "Bearer " + loginState.token,
          // },
          data: obj,
          method: "post",
        })
          .then((res) => {
            setScheduleLoader(false);
            handleScheduleModalCancel();
            props.getScheduleRefreshData(playlistId);
            window.location.reload();
          })
          .catch((err) => setScheduleLoader(false));
      } else {
        setScheduleLoader(false);

        toast.error("Please Select Duration");
      }
    }
  };
  useEffect(() => {
    if (props.refreshScheduleData) {
      console.log(props.refreshScheduleData, "dsadadadadsa");
      setScheduleType(props.refreshScheduleData?.type);
      setSaveRestore(props.refreshScheduleData.backup);
      setScheduleDateTime(
        new Date(Number(props.refreshScheduleData.nextrefreshtTime))
      );
    }
  }, [props.refreshScheduleData]);
  const ScheduleFixFn = () => {
    setScheduleLoaderFix(true);
    console.log(props.fixScheduleFData, scheduleDateTime);
    if (props.fixScheduleFData) {
      let obj = {
        nextFixPlaylistTime: props.fixScheduleFData.nextrefreshtTime,
        fixPlaylistTime: props.fixScheduleFData.refreshTime,
        type: scheduleFixType,
        accessToken: loginState.refreshToken,
        backup: saveRestore,
      };
      console.log(
        scheduleDateFixTimeDatePicker,
        "scheduleDateFixTimeDatePicker"
      );
      if (scheduleDateFixTimeDatePicker) {
        // @ts-ignore
        // refreshValue,
        obj.refreshValue = new Date(scheduleDateFixTimeDatePicker).getTime();
      }
      axios(`${BASE_URL}/updatefixplaylistLogs/${props.fixScheduleFData._id}`, {
        // headers: {
        //   Authorization: "Bearer " + loginState.token,
        // },
        data: obj,
        method: "put",
      }).then((res) => {
        setScheduleLoaderFix(false);
        handleScheduleModalCancel();
        props.getScheduleRefreshData(playlistId);
        window.location.reload();
      });
    } else {
      if(scheduleFixType){

      let obj = {
        playlist_id: playlistId,
        accessToken: loginState.refreshToken,
        type: scheduleFixType,
        backup: saveRestore,
        fixPlaylistTime: scheduleDateFixTimeDatePicker,
      };
      axios(`${BASE_URL}/addfixplaylistlogs`, {
        // headers: {
        //   Authorization: "Bearer " + loginState.token,
        // },
        data: obj,
        method: "post",
      }).then((res) => {
        setScheduleLoaderFix(false);
        handleScheduleModalCancel();
        props.getScheduleFixData(playlistId);
        // window.location.reload();
      }).catch(()=>setScheduleLoaderFix(false))
    } else {
      setScheduleLoaderFix(false);

      toast.error("Please Select Duration");
    }
  }
  };

  useEffect(() => {
    if (props.fixScheduleFData) {
      console.log(props.fixScheduleFData, "dsadadadadsa");
      setScheduleFixType(props.fixScheduleFData?.type);
      setSaveRestore(props.fixScheduleFData.backup);
      setScheduleFixDateTime(
        new Date(Number(props.fixScheduleFData.nextrefreshtTime))
      );
    }
  }, [props.fixScheduleFData]);
  const deletePlaylistRefresh = () => {
    axios(`${BASE_URL}/deleteRefreshlogs/${playlistId}`, {
      // headers: {
      //   Authorization: "Bearer " + loginState.token,
      // },
      method: "delete",
    }).then((res) => {
      window.location.reload();
    });
  };
  const deletePlaylistFix = () => {
    axios(`${BASE_URL}/deletefixplaylistLogs/${playlistId}`, {
      // headers: {
      //   Authorization: "Bearer " + loginState.token,
      // },
      method: "delete",
    }).then((res) => {
      window.location.reload();
    });
  };

  const confirm = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.success("Click on Yes");
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    message.error("Click on No");
  };
  return (
    <>
      <PlayListBannerStyle>
        <div className={`bannerDiv ${props.fixed ? "hidebanner" : ""}`}>
          <div className="playlistActions">
            <div className="innerPlaylistActions">
              <div className="actionDiv" style={{ position: "relative" }}>
                <PlanToast
                  available={["free", "pro", "unlimited"]}
                  titleHeading={"ADD IN BULK"}
                >
                  <Popover
                    content={
                      <p>
                        Enable bulk mode to add songs with the current date and
                        time
                      </p>
                    }
                    placement="bottomLeft"
                  >
                    <p>
                      <span className="textAction">Add in bulk:</span>{" "}
                      <BulkModeCTA1 />
                    </p>
                  </Popover>
                </PlanToast>
              </div>
              <div className="actionDiv">
                <PlanToast
                  available={["pro", "unlimited"]}
                  titleHeading={"Songplace only"}
                >
                  <Popover
                    content={
                      <p>
                        Enable Songplace only mode to view songs with their
                        scheduled date and time
                      </p>
                    }
                    placement="bottomLeft"
                  >
                    <p>
                      <span className="textAction">Songplace only:</span>{" "}
                      <Songplace />
                    </p>
                  </Popover>
                </PlanToast>
              </div>
            </div>
            {/* {props.refreshScheduleData ? (
              <div className="lastStatus">
                <p>
                  Next update on:{" "}
                  {moment(
                    Number(props.refreshScheduleData.nextrefreshtTime)
                  ).format("DD/MM/YYYY hh:mm A")}
                </p>
              </div>
            ) : null} */}
            {/* <div className="actionDiv">
              <PlanToast
                available={["pro", "unlimited"]}
                titleHeading={"Bulk action"}
              >
                <Popover content={<p>Edit or delete your song in bulk</p>} placement="bottomLeft">
                  <p>
                    <span className="textAction">Bulk action:</span>{" "}
                    <span>
                      <BulkActionToggle bulkAction={props.bulkAction}
                        setBulkAction={props.setBulkAction} />
                    </span>
                  </p>
                </Popover>
              </PlanToast>
            </div> */}
          </div>
          <div className="container">
            <div className="text-container">
              <div className="platform">
                <img
                  alt="spotify"
                  src={"./../static/Spotify_Logo_RGB_Green.png"}
                />{" "}
              </div>
              <div className="row1">
                {theme === "dark" ? (
                  <PlayListNew color={"white"} />
                ) : (
                  <PlayListNew color={"#1D1D1D"} />
                )}
                <p className="p1">Playlist</p>
              </div>
              <div className="row-space" style={{}}>
                <div>
                  <h1 className="title">{truncateText(title, 23)}</h1>
                  <p className="desc">{updatedDesc ? updatedDesc : desc}</p>
                  {/* <p className="desc">
                  {totalSongs} {array.length ? `- ${array.length} Owner` : ""}
                </p> */}
                </div>
              </div>
              <div className="p2">
                <div style={{ display: "flex" }}>
                  <p>Spotify</p>
                  <span className="platForm">-</span>
                  <p className="platForm">
                    {totalSongs} & {props.currentPlaylistData?.followers?.total}{" "}
                    Followers{" "}
                  </p>
                </div>
                <button
                  className="editPlaylistTitle"
                  onClick={() => {
                    setIsEditPlaylistModalVisible(true);
                  }}
                >
                  <Popover
                    content={<p>Edit playlist name or description</p>}
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <img src={"./static/images/EditIcon.svg"} />
                    </div>
                  </Popover>
                </button>
                <Popover content={<p>Copy playlist URL</p>} placement="top">
                  <button
                    className="editPlaylistTitle"
                    onClick={() => {
                      // console.log(window.location.pathname,'sadasdsasa')
                      let str = `https://open.spotify.com${window.location.pathname}`;
                      navigator.clipboard.writeText(str);
                      message.success("Playlist URL copied");
                    }}
                  >
                    <img
                      alt="notes.svg"
                      src={"./static/images/copyIcon.svg"}
                      width="20px"
                      height="20px"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </button>
                </Popover>
                <Popover content={<p>Refresh playlist</p>} placement="top">
                  <button
                    className="editPlaylistTitle"
                    onClick={() => {
                      // console.log(window.location.pathname,'sadasdsasa')
                      props.refreshPlaylistFunction();
                    }}
                  >
                    <img
                      alt="notes.svg"
                      src={"./static/refreshicon.svg"}
                      width="20px"
                      height="20px"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </button>
                </Popover>
                <Popover content={<p>Fix song position</p>} placement="top">
                  <button
                    className="editPlaylistTitle editPlaylistTitleSvg"
                    onClick={() => {
                      // console.log(window.location.pathname,'sadasdsasa')
                      setisModalVisible(true);
                      // props.refreshPlaylistFunction();
                    }}
                  >
                    <FixIcon />
                  </button>
                </Popover>

                {/* <button 
                     className="editPlaylistTitle"
                     
                   >
                      <Popover
                        content={<p>Edit playlist name or description</p>}
                        placement="topLeft"
                      >
                        <div
                          style={{
                            cursor:"pointer",
                            marginLeft: "5px",
    marginTop: "-3px",
                          }}
                          onClick={() => {
                            setIsEditPlaylistModalVisible(true);
                          }}
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1955_58825)">
                              <path
                                d="M19.0607 8.20368L16.814 5.95701C16.7143 5.85703 16.5958 5.77771 16.4654 5.72359C16.335 5.66946 16.1952 5.6416 16.054 5.6416C15.9128 5.6416 15.773 5.66946 15.6426 5.72359C15.5122 5.77771 15.3937 5.85703 15.294 5.95701L6.154 15.1503L5.414 18.357C5.38247 18.5125 5.38578 18.6729 5.4237 18.827C5.46162 18.981 5.53319 19.1246 5.63328 19.2477C5.73336 19.3707 5.85948 19.47 6.00254 19.5385C6.1456 19.607 6.30206 19.6429 6.46066 19.6437C6.54264 19.6527 6.62535 19.6527 6.70733 19.6437L9.94066 18.9303L19.0607 9.72368C19.1606 9.62397 19.24 9.50552 19.2941 9.37511C19.3482 9.24469 19.3761 9.10488 19.3761 8.96368C19.3761 8.82248 19.3482 8.68267 19.2941 8.55225C19.24 8.42184 19.1606 8.30339 19.0607 8.20368ZM9.24733 17.697L6.80733 18.237L7.37399 15.817L14.254 8.89035L16.134 10.7703L9.24733 17.697ZM16.8873 10.017L15.0073 8.13701L16.0407 7.08368L17.934 8.97701L16.8873 10.017Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1955_58825">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0.25 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </Popover>
                    </button> */}
              </div>

              <div className="songPlayListButtons hide-responsive">
                <button
                  className="addSong"
                  onClick={() => {
                    setAddToPlaylistModal(true);
                  }}
                >
                  <PlanToast
                    available={["free", "pro", "unlimited"]}
                    titleHeading={"Add song"}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1955_58822)">
                        <path
                          d="M8.5801 6.46961H13.6001V8.52961H8.5801H8.2801V8.82961V13.8496H6.2201V8.82961V8.52961H5.9201H0.900098V6.46961H5.9201H6.2201V6.16961V1.14961H8.2801V6.16961V6.46961H8.5801Z"
                          fill="white"
                          stroke="#007AFF"
                          stroke-width="0.6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1955_58822">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0.25 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Add song
                  </PlanToast>
                </button>
                {/* <button
                  className="editPlaylist"
                  onClick={() => {
                    setIsEditPlaylistModalVisible(true);
                  }}
                >
                  <Popover
                    content={<p>Edit playlist name or description</p>}
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1955_58825)">
                          <path
                            d="M19.0607 8.20368L16.814 5.95701C16.7143 5.85703 16.5958 5.77771 16.4654 5.72359C16.335 5.66946 16.1952 5.6416 16.054 5.6416C15.9128 5.6416 15.773 5.66946 15.6426 5.72359C15.5122 5.77771 15.3937 5.85703 15.294 5.95701L6.154 15.1503L5.414 18.357C5.38247 18.5125 5.38578 18.6729 5.4237 18.827C5.46162 18.981 5.53319 19.1246 5.63328 19.2477C5.73336 19.3707 5.85948 19.47 6.00254 19.5385C6.1456 19.607 6.30206 19.6429 6.46066 19.6437C6.54264 19.6527 6.62535 19.6527 6.70733 19.6437L9.94066 18.9303L19.0607 9.72368C19.1606 9.62397 19.24 9.50552 19.2941 9.37511C19.3482 9.24469 19.3761 9.10488 19.3761 8.96368C19.3761 8.82248 19.3482 8.68267 19.2941 8.55225C19.24 8.42184 19.1606 8.30339 19.0607 8.20368ZM9.24733 17.697L6.80733 18.237L7.37399 15.817L14.254 8.89035L16.134 10.7703L9.24733 17.697ZM16.8873 10.017L15.0073 8.13701L16.0407 7.08368L17.934 8.97701L16.8873 10.017Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1955_58825">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(0.25 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Edit playlist
                    </div>
                  </Popover>
                </button> */}
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Refresh Playlist"}
                  >
                    <Popover
                      content={
                        <p>
                          Schedule "refresh playlist" based on your preferred
                          date and time
                        </p>
                      }
                      placement="topLeft"
                    >
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            handleScheduleModalShow();
                          }}
                        >
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.8333 0.499023C11.2936 0.499023 11.6667 0.872123 11.6667 1.33236V2.16569H13.3333C14.2538 2.16569 15 2.91188 15 3.83236V13.8324C15 14.7529 14.2538 15.499 13.3333 15.499H1.66667C0.746192 15.499 0 14.7529 0 13.8324V3.83236C0 2.91188 0.746192 2.16569 1.66667 2.16569H3.33333V1.33236C3.33333 0.872123 3.70642 0.499023 4.16667 0.499023C4.62691 0.499023 5 0.872123 5 1.33236V2.16569H10V1.33236C10 0.872123 10.3731 0.499023 10.8333 0.499023ZM13.3333 3.83236H1.66667V13.8324H13.3333V3.83236ZM9.85342 5.81462C10.1788 5.48919 10.7065 5.48919 11.0319 5.81462C11.3574 6.14006 11.3574 6.66769 11.0319 6.99311L6.91308 11.112C6.58433 11.4407 6.05142 11.4407 5.72276 11.112L3.96088 9.35019C3.63544 9.02469 3.63544 8.49711 3.96088 8.17161C4.28632 7.84619 4.81396 7.84619 5.13939 8.17161L6.31792 9.35019L9.85342 5.81462Z"
                              fill="white"
                            />
                          </svg>
                          Schedule refresh
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Refresh Playlist"}
                  >
                    <Popover
                      content={<p>Check for backups</p>}
                      placement="topLeft"
                    >
                      {props.PlaylistRefreshRecord &&
                      props.PlaylistRefreshRecord.length &&
                      props.PlaylistRefreshRecord[0]?.uris &&
                      JSON.parse(props.PlaylistRefreshRecord[0].uris).length ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            setisRestoreModalVisible(true);
                          }}
                        >
                          <BackupIcon />
                          Restore backups
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                {/* <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Fix position"}
                  >
                    <Popover
                      content={
                        <p>Bring songs back to their original positions</p>
                      }
                      placement="topLeft"
                    >
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            setisModalVisible(true);
                          }}
                        >
                          <FixIcon />
                          Fix playlist
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div> */}
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Refresh Playlist"}
                  >
                    <Popover
                      content={
                        <p>
                          Schedule "Fix song position" based on a custom date
                          and time
                        </p>
                      }
                      placement="topLeft"
                    >
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            handleScheduleFixModalShow();
                          }}
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.27261 5L8.17669 7.02824L10.385 7.26131L8.73545 8.74791L9.1962 10.9202L7.27261 9.81073L5.34902 10.9202L5.80976 8.74791L4.16017 7.26131L6.36852 7.02824L7.27261 5Z"
                              fill="white"
                            />
                            <path
                              d="M10.8333 0C11.2936 0 11.6667 0.3731 11.6667 0.833333V1.66667H13.3333C14.2538 1.66667 15 2.41286 15 3.33333V13.3333C15 14.2538 14.2538 15 13.3333 15H1.66667C0.746192 15 0 14.2538 0 13.3333V3.33333C0 2.41286 0.746192 1.66667 1.66667 1.66667H3.33333V0.833333C3.33333 0.3731 3.70642 0 4.16667 0C4.62691 0 5 0.3731 5 0.833333V1.66667H10V0.833333C10 0.3731 10.3731 0 10.8333 0ZM13.3333 3.33333H1.66667V13.3333H13.3333V3.33333Z"
                              fill="white"
                            />
                          </svg>
                          Schedule fix playlist
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                {/* {props.selectedSongs && props.selectedSongs.length ?
                  <div style={{ position: "relative" }}>

                    <Popover content={<p>Deleted deleted songs</p>} placement="topLeft">
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            props.showModalDeleteSelected();
                          }}
                        >
                          <DeleteOutlineIcon />
                          Deleted Selected Song
                        </button>
                      ) : null}
                    </Popover>
                  </div>
                  : null} */}
                <AddToPlayListModalStyle
                  visible={props.isModalVisible}
                  onOk={handleOk}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <div>
                    <h1 className="heading">Refresh playlist</h1>
                  </div>
                  <p className="refreshDescription" style={{ opacity: "0.5" }}>
                    Refreshing the playlist will change the start date and time
                    of each song to the current date and time. Are you sure you
                    want to continue?
                  </p>
                  <p className="refreshDescription" style={{ opacity: "0.5" }}>
                    Note: To help prevent any data loss, we recommend creating a
                    backup for this playlist.
                  </p>

                  <div className="checkBoxSaveVersion">
                    <div className="note">
                      <div
                        className="checkVersion"
                        onClick={() => {
                          setSaveRestore(!saveRestore);
                        }}
                      >
                        {saveRestore ? (
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                          </svg>
                        ) : null}
                      </div>
                      <span style={{ marginLeft: "0px" }}>
                        Create a backup for this playlist.
                      </span>
                    </div>
                  </div>
                  <div className="footer refreshModalFooter">
                    {/* {props.PlaylistRefreshRecord &&
                      props.PlaylistRefreshRecord.length &&
                      props.PlaylistRefreshRecord[0]?.uris &&
                      JSON.parse(props.PlaylistRefreshRecord[0].uris).length ? (
                      <FilledButtonStyle
                        border="1px solid rgb(0, 122, 255)"
                        background="transparent"
                        type="button"
                        onClick={() => {
                          props.setIsModalVisible(false);
                          setisRestoreModalVisible(true);
                          // console.log(props.PlaylistRefreshRecord, 'PlaylistRefreshRecord')
                        }}
                        className="btn1"
                      >
                        Restore playlist songs
                      </FilledButtonStyle>
                    ) : (
                      <div></div>
                    )} */}
                    <div></div>

                    <div className="yesNoButtons">
                      <FilledButtonStyle
                        background="transparent"
                        width="90px"
                        type="button"
                        onClick={handleCancel}
                        className="btn1"
                      >
                        No
                      </FilledButtonStyle>

                      <FilledButtonStyle
                        disabled={!selectRefreshFreq}
                        type="submit"
                        className="btn2"
                        style={{ width: "220px" }}
                        onClick={() => {
                          props.handleRefresh(
                            selectRefreshFreq,
                            deleteAllRefresh,
                            refreshPlaylist,
                            token,
                            songs,
                            saveRestore
                          );
                        }}
                      >
                        Refresh now
                      </FilledButtonStyle>

                      {/* <FilledButtonStyle
                        disabled={!selectRefreshFreq}
                        type="submit"
                        className="btn2"
                        style={{ width: "220px", marginLeft: "10px" }}
                        onClick={() => {
                          handleCancel();
                          handleScheduleModalShow();
                        }}
                      >
                        Schedule refresh
                      </FilledButtonStyle> */}
                    </div>
                  </div>
                </AddToPlayListModalStyle>

                <ScheduleRefreshModal
                  scheduleRefreshModal={scheduleRefreshModal}
                  handleScheduleModalhandleOk={handleScheduleModalhandleOk}
                  handleScheduleModalCancel={handleScheduleModalCancel}
                  scheduleDateTime={scheduleDateTime}
                  setScheduleDateTime={setScheduleDateTime}
                  scheduleDateTimeDatePicker={scheduleDateTimeDatePicker}
                  setScheduleDateTimeDatePicker={setScheduleDateTimeDatePicker}
                  scheduleType={scheduleType}
                  setScheduleType={setScheduleType}
                  setOpenDatePicker={setOpenDatePicker}
                  setdatePickeropen={setdatePickeropen}
                  saveRestore={saveRestore}
                  setSaveRestore={setSaveRestore}
                  scheduleLaoder={scheduleLaoder}
                  showModalDelete={showModalDelete}
                  ScheduleRefreshFn={ScheduleRefreshFn}
                  props={props}
                />
                <ScheduleFixModal
                  scheduleRefreshModal={scheduleFixModal}
                  handleScheduleModalhandleOk={handleScheduleFixModalhandleOk}
                  handleScheduleModalCancel={handleScheduleFixModalCancel}
                  scheduleDateTime={scheduleFixDateTime}
                  setScheduleDateTime={setScheduleFixDateTime}
                  scheduleDateTimeDatePicker={scheduleDateFixTimeDatePicker}
                  setScheduleDateTimeDatePicker={
                    setScheduleFixDateTimeDatePicker
                  }
                  scheduleType={scheduleFixType}
                  setScheduleType={setScheduleFixType}
                  setOpenDatePicker={setOpenDatePickerFix}
                  setdatePickeropen={setdatePickeropenFix}
                  saveRestore={saveRestore}
                  setSaveRestore={setSaveRestore}
                  scheduleLaoder={scheduleLaoderFix}
                  showModalDelete={showModalFixDelete}
                  ScheduleRefreshFn={ScheduleFixFn}
                  props={props}
                />
              </div>
            </div>
            <div
              className="playListPic"
              style={{ width: image ? "" : "250px" }}
            >
              {image ? (
                <img
                  className="image imagecontainerdirection"
                  src={image}
                  alt="playlist thumbnail"
                />
              ) : (
                <svg
                  className="playlistIcon"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M429.46 32.07c-23.6 6.53-205.55 58.81-250.54 71.43a4 4 0 00-2.92 3.83v247a2 2 0 01-1.33 1.89l-27.85 9.55c-19 7.44-66.82 16.68-66.82 59.19 0 35.54 24.63 51.54 45.86 54.28a52.06 52.06 0 007.81.8c7.37 0 36.38-7.08 53.3-18.08C208 448.25 208 448 208 412V202c0-.9.62-.84 1.48-1.07l188-51.92a2 2 0 012.53 2v155.54a2 2 0 01-1.36 1.89c-8.9 3-19.23 6.5-26.48 9.12C341.39 328.68 304 335.65 304 376c0 38.51 28.26 54.58 46.3 55.83a87.37 87.37 0 0021.33-1c9-1.38 24.09-5.9 38.14-14.86C432 401.79 432 401.51 432 360V34a2 2 0 00-2.54-1.93z"></path>
                </svg>
              )}
            </div>
          </div>
          <AddToPlayListModalStyle
            visible={isEditPlaylistModalVisible}
            onOk={handleOk}
            footer={null}
            onCancel={() => {
              setname("");
              setdescription("");
              setIsEditPlaylistModalVisible(false);
            }}
          >
            <div>
              <h1 className="heading">Edit playlist details</h1>
            </div>
            {isEditPlaylistModalVisible ? (
              <>
                <div className="formAddToPlaylist">
                  <div className="formPlaylist">
                    <div className="playlistPosition">
                      <label className="label">
                        <p>Playlist name</p>
                        <Input
                          // type="number"
                          value={name}
                          placeholder="Playlist name"
                          onChange={(value) => {
                            setname(value.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <label className="label">
                      <p>Description</p>
                      <Input.TextArea
                        // type="text"
                        className="addNoteText"
                        // showCount
                        maxLength={300}
                        value={description}
                        placeholder="Description"
                        rows={4}
                        onChange={(value: any) => {
                          setdescription(value.target.value);
                        }}
                      />
                    </label>
                    <p className="textarea_count">{description.length} / 300</p>
                  </div>
                </div>
                <div className="buttonDiv">
                  <button
                    className="addSong"
                    onClick={() =>
                      handleEditPlaylistDetails({
                        playlistId: playlistId,
                        playlistname: name,
                        playlistDescription: description,
                      })
                    }
                  >
                    Done
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </AddToPlayListModalStyle>
        </div>

        <AddToPlayListModalStyle
          visible={isModalVisible}
          footer={null}
          onCancel={() => {
            setisModalVisible(false);
          }}
          className="syncModal"
        >
          <div>
            <h1 className="heading">Fixed position</h1>
          </div>
          <p className="refreshDescription">
            Resetting the positions will bring songs back to their originally
            fixed positions. Are you sure you want to continue?
          </p>
          <div className="footer">
            <FilledButtonStyle
              background="transparent"
              width="90px"
              type="button"
              onClick={() => {
                setisModalVisible(false);
              }}
              className="btn1"
            >
              Cancel
            </FilledButtonStyle>
            <FilledButtonStyle
              disabled={!selectRefreshFreq}
              type="submit"
              className="btn2"
              style={{ width: "230px" }}
              onClick={() => {
                setisModalVisible(false);
                setsyncModal(true);
                props.syncdatafromspotify({
                  syncModal,
                  setSyncModal: setsyncModal,
                });
              }}
            >
              Confirm
            </FilledButtonStyle>
          </div>
        </AddToPlayListModalStyle>

        <AddToPlayListModalStyle
          visible={isRestoreModalVisible}
          footer={null}
          onCancel={() => {
            setisRestoreModalVisible(false);
          }}
          className="syncModal"
        >
          <div>
            <h1 className="heading">Restore songs</h1>
          </div>
          <p className="refreshDescription">
            You can restore one or more backups by choosing the versions below:
          </p>
          <div className="verisonsUi">
            <div className="innerVerisonsUi">
              <p>Available backups:</p>
              {props.PlaylistRefreshRecord &&
                props.PlaylistRefreshRecord.length &&
                props.PlaylistRefreshRecord[0]?.uris &&
                JSON.parse(props.PlaylistRefreshRecord[0]?.uris).map((a, i) => (
                  <div
                    className="verisons"
                    onClick={() => {
                      setVersionRestore(a?.version);
                    }}
                  >
                    <div className="checkVersion">
                      {versionRestore === a?.version ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                        </svg>
                      ) : null}
                    </div>
                    Backup {a.date ? new Date(a.date).toDateString() : i + 1}
                    <span className="songsLength">
                      ({a?.uris?.length} Songs)
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="footer">
            <FilledButtonStyle
              background="transparent"
              width="90px"
              type="button"
              onClick={() => {
                setisRestoreModalVisible(false);
              }}
              className="btn1"
            >
              Cancel
            </FilledButtonStyle>
            <FilledButtonStyle
              disabled={!versionRestore || restoreModalLoader}
              type="submit"
              className="btn2 restoreButton"
              style={{ width: "230px" }}
              onClick={() => {
                setRestoreModalLoader(true);
                // setisRestoreModalVisible(false);
                // setrestoreModal(true);
                props.restorePlaylsitSongs(
                  restoreModal,
                  setrestoreModal,
                  versionRestore
                );
              }}
            >
              {restoreModalLoader ? <Spinner /> : "Restore"}
            </FilledButtonStyle>
          </div>
        </AddToPlayListModalStyle>

        <SyncModal
          isModalVisible={syncModal}
          setIsModalVisible={setsyncModal}
        />
        <RestoreModal
          isModalVisible={restoreModal}
          setIsModalVisible={setrestoreModal}
        />
      </PlayListBannerStyle>
      <DeleteModal
        handleCancel={handleCancelDelete}
        showModal={showModalDelete}
        isModalOpen={isModalOpenDelete}
        handleOk={handleOkDelete}
        heading={"Delete schedule"}
        headingText={"Are you sure you want to remove this schedule?"}
        // @ts-ignore
        positionWarning={""}
        button1={"Yes, remove"}
        button2={"Cancel"}
      />
      <DeleteModal
        handleCancel={handleCancelFixDelete}
        showModal={showModalFixDelete}
        isModalOpen={isModalOpenFixDelete}
        handleOk={handleOkFixDelete}
        heading={"Delete schedule"}
        headingText={"Are you sure you want to remove this schedule?"}
        // @ts-ignore
        positionWarning={""}
        button1={"Yes, remove"}
        button2={"Cancel"}
      />
    </>
  );
};

export default PlayListBanner;
